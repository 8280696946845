import * as React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import styled from "styled-components"
import _ from "lodash"
import {
  PostDetailsWrapper,
  PostTitle,
  PostDate,
  PostPreview,
  PostDescriptionWrapper,
  PostDescription,
  PostTags,
} from "./postDetails.style"
import ButtonStyle from "../../ui/Button/Button.style"

const YoutubeLinkWrapper = styled.div`
  display: flex;
  justify-content: center;
`

type PostDetailsProps = {
  title: string
  date?: string
  preview?: any
  previewAlt?: string
  description: any
  tags?: []
  youtubeLink?: string
  className?: string
  imagePosition?: "left" | "top"
}

const PostDetails: React.FunctionComponent<PostDetailsProps> = ({
  title,
  date,
  preview,
  description,
  tags,
  youtubeLink,
  className,
  imagePosition,
  ...props
}) => {
  const addClass: string[] = ["post_details"]

  if (imagePosition == "left") {
    addClass.push("image_left")
  }

  if (className) {
    addClass.push(className)
  }

  //Random Placeholder Color
  const placeholderColors = [
    "#55efc4",
    "#81ecec",
    "#74b9ff",
    "#a29bfe",
    "#ffeaa7",
    "#fab1a0",
    "#e17055",
    "#0984e3",
    "#badc58",
    "#c7ecee",
  ]
  const setColor =
    placeholderColors[Math.floor(Math.random() * placeholderColors.length)]

  return (
    <PostDetailsWrapper {...props} className={addClass.join(" ")}>
      {imagePosition == "left" ? (
        <>
          {preview == null ? null : (
            <PostPreview className="post_preview">
              <Img fluid={preview} alt={title} backgroundColor={setColor} />
            </PostPreview>
          )}
        </>
      ) : (
        ""
      )}

      {imagePosition == "top" ? (
        <>
          <PostTitle>{title}</PostTitle>
          <PostDate>{date}</PostDate>
        </>
      ) : (
        ""
      )}

      {imagePosition == "top" ? (
        <>
          {preview == null ? null : (
            <PostPreview className="post_preview">
              {youtubeLink ? (
                <a href={youtubeLink} target="_blank">
                  <Img fluid={preview} alt={title} backgroundColor={setColor} />
                </a>
              ) : (
                <Img fluid={preview} alt={title} backgroundColor={setColor} />
              )}
            </PostPreview>
          )}
        </>
      ) : (
        ""
      )}
      <PostDescriptionWrapper className="post_des_wrapper">
        {imagePosition == "left" ? (
          <>
            <PostTitle>{title}</PostTitle>
            <PostDate>{date}</PostDate>
          </>
        ) : (
          ""
        )}
        <PostDescription
          dangerouslySetInnerHTML={{ __html: description }}
          className="post_des"
        />
        {youtubeLink && (
          <YoutubeLinkWrapper>
            <ButtonStyle
              as="a"
              href={youtubeLink}
              target="_blank"
              style={{ width: "260px" }}
            >
              Смотреть видео
            </ButtonStyle>
          </YoutubeLinkWrapper>
        )}
        {tags == null ? null : (
          <PostTags>
            {tags.map((tag, index) => (
              <Link key={index} to={`/tags/${_.kebabCase(tag)}/`}>
                {`#${tag}`}
              </Link>
            ))}
          </PostTags>
        )}
      </PostDescriptionWrapper>
    </PostDetailsWrapper>
  )
}

PostDetails.defaultProps = {
  imagePosition: "top",
}

export default PostDetails
